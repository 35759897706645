import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useFirestore } from "../firebase/FirestoreContext";
import { currentProductPrice } from "../functions/currentProductPrice";
import addToCart from "../functions/addToCart";
import "../styles/global.css"; // Import global styles
import ListingPriceFormater from "./ListingPriceFormater";
import { EnvContext } from "../context/EnvContext";
import Cookies from "js-cookie";
import config from "../config";
import { logAnalyticsEvent } from "../firebase/firebaseConfig";
import { UserContext } from "../context/UserContext";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarIcon from "@mui/icons-material/Star";
import { min } from "moment/moment";
import { textAlign } from "@mui/system";

const ListItem = ({ product, index, dataMultiple, displayType = "vertical" }) => {
  //
  const environment = useContext(EnvContext);
  const { cart } = useContext(UserContext);
  //
  const navigate = useNavigate();
  const firestore = useFirestore();
  const auth = getAuth();
  const [addedToCart, setAddedToCart] = useState(false);
  const [priceInfo, setPriceInfo] = useState({});

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // Fetch the price information when the component mounts
  useEffect(() => {
    const fetchPriceInfo = async () => {
      const result = await currentProductPrice(product);
      setPriceInfo(result); // Store the result in the state
    };

    fetchPriceInfo();
  }, [product]); // Dependency on the product, so it runs when the product changes

  const handleAddToCart = async (listingID) => {
    try {
      setAddedToCart(true);
      const user = auth.currentUser || null; // Get the current authenticated user

      await addToCart(listingID, user, firestore, 1, collectionPath);

      // Reset addedToCart state after 1 second
      setTimeout(() => {
        setAddedToCart(false);
      }, 1000);
    } catch (error) {
      console.error("Error adding to cart: ", error);
      // Reset addedToCart state in case of error
      setAddedToCart(false);
    }
  };

  const handleRemoveFromCart = async (sellerProductId, collectionPath) => {
    try {
      await addToCart(sellerProductId, auth.currentUser, 0, collectionPath); // Remove item
    } catch (error) {
      console.error("Error removing from cart:", error);
    }
  };

  const handleDecreaseQuantity = async (listingID) => {
    try {
      setAddedToCart(true);
      const user = auth.currentUser || null; // Get the current authenticated user

      await addToCart(listingID, user, firestore, -1, collectionPath);

      // Reset addedToCart state after 1 second
      setTimeout(() => {
        setAddedToCart(false);
      }, 1000);
    } catch (error) {
      console.error("Error adding to cart: ", error);
      // Reset addedToCart state in case of error
      setAddedToCart(false);
    }
  };

  const saveScrollPosition = () => {
    const scrollY = window.scrollY; // Get the current Y scroll position
    Cookies.set("ShopPageScrollPosition", scrollY); // Save it in localStorage
  };

  const handleNavigate = () => {
    //
    logAnalyticsEvent("select_content", {
      content_type: "product",
      item_id: product.seller_product_id,
      item_name: product.product_name,
      environment: environment,
    });
    //
    saveScrollPosition(); // Save scroll position before navigating
    navigate(`Listing/${product?.seller_product_id}`); // Navigate to the product detail page
  };

  //const rating = product?.rating?.rating ? parseFloat(product.rating.rating).toFixed(1) : "0.0";
  //const votes = product?.rating?.votes || 0;

  // calculate rating and initial rating
  let rating = parseFloat(product?.rating?.rating) || 0;
  let votes = parseFloat(product?.rating?.votes) || 0;
  let initialRating = parseFloat(product?.rating_initial?.rating) || 0;
  let initialVoting = parseFloat(product?.rating_initial?.voting) || 0;

  // if all is 0
  if (votes == 0 && initialVoting == 0) {
    rating = initialRating;
    votes = initialVoting;
  } else {
    rating = parseFloat((rating + initialRating * initialVoting) / (initialVoting + votes)).toFixed(1);
    votes = votes + initialVoting;
  }

  let renderPrice;
  let renderBrand;
  let renderName;
  let renderRating;

  renderPrice = (
    <div style={{ ...styles.addToBag.priceSection, padding: 0 }}>
      {priceInfo && product ? <ListingPriceFormater priceInfo={priceInfo} product={product} type={"prices"} /> : null}
    </div>
  );

  renderBrand = <div style={{ fontSize: 14, fontWeight: "200", padding: "5px 0px", fontWeight: 600 }}>{product?.brand_name}</div>;

  renderName = (
    <h3
      style={{
        ...styles.productName.name,
        // max two lines
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: 0,
      }}
    >
      {product?.product_name || ""}
    </h3>
  );

  renderRating = (
    <div style={{ ...styles.productName.rating }}>
      <span style={styles.productName.ratingText}>
        {rating || "0.0"}
        <StarIcon style={styles.productName.iconStar} />
        <span style={{ opacity: 0.5 }}>{` (${votes})`}</span>
      </span>
    </div>
  );

  return (
    <div
      style={{
        ...styles.listItemContainer,
      }}
    >
      <div
        style={{ ...styles.listItem, position: "relative" }}
        onClick={() => {
          handleNavigate();
        }}
      >
        {/* 
        SOOPERSTOCK DEAL & OFFER RIBBON
        */}
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            height: "25px",
          }}
        >
          {priceInfo && product && product?.listing_stock_amount !== 0 ? (
            <ListingPriceFormater
              priceInfo={priceInfo}
              product={product}
              type={"sooperdeal_counter_with_flames"}
              containerStyle={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 0,
                fontSize: ".8rem",
                padding: "5px 0",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                borderBottom: ".5px solid rgba(100,100,100,.3)",
                backgroundColor: "#f7ff00",
              }}
            />
          ) : null}
          {priceInfo && priceInfo?.priceType === "offer_price" && product?.listing_stock_amount !== 0 ? (
            <div style={{ ...styles.offerRibon }}>
              {Math.round(((priceInfo.listing_price - priceInfo.finalPrice) / priceInfo.listing_price) * 100) + "% off"}
            </div>
          ) : null}
        </div>

        {/*
        CONTENT
        */}

        <div
          style={{
            display: "flex",
            flexDirection: displayType === "vertical" ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              marginBottom: displayType === "vertical" ? "10px" : 0,
              marginRight: displayType === "vertical" ? 0 : "10px",
            }}
          >
            {/*
          IMAGE AND ADD TO CART
          */}
            <div style={styles.listItemImageContainer}>
              <img
                src={product?.images && product?.images?.length > 0 ? product?.images[0] : "/images/no_product_image.png"}
                alt={product?.name || "Product"}
                style={styles.listItemImage}
              />
              {/* Add to bag section */}
              <div
                style={{
                  ...styles.addToBag.container,
                  width: cart.some((item) => item.seller_product_id === product.seller_product_id)
                    ? "100px" // Expanded width when product is in cart
                    : "40px", // Compact width when product is not in cart
                  transition: "width 0.3s ease", // Add smooth transition
                }}
              >
                {cart.some((item) => item.seller_product_id === product.seller_product_id) ? (
                  // Render trash, quantity, and "+" when the product is in the cart
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {cart.find((item) => item.seller_product_id === product.seller_product_id)?.quantity === 1 ? (
                      <>
                        <div
                          style={{ ...styles.addToBag.button }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDecreaseQuantity(product.seller_product_id);
                          }}
                        >
                          <DeleteOutlineIcon style={{ fontSize: 16, fontWeight: 800 }} />
                        </div>
                        <div style={styles.addToBag.quantity}>{cart.find((item) => item.seller_product_id === product.seller_product_id).quantity}</div>
                        <div
                          style={{ ...styles.addToBag.button }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddToCart(product.seller_product_id);
                          }}
                        >
                          <AddIcon style={{ fontSize: 16, fontWeight: 800 }} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{ ...styles.addToBag.button }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDecreaseQuantity(product.seller_product_id);
                          }}
                        >
                          <RemoveIcon style={{ fontSize: 16, fontWeight: 800 }} />
                        </div>
                        <div style={styles.addToBag.quantity}>{cart.find((item) => item.seller_product_id === product.seller_product_id).quantity}</div>
                        <div
                          style={{ ...styles.addToBag.button }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddToCart(product.seller_product_id);
                          }}
                        >
                          <AddIcon style={{ fontSize: 16, fontWeight: 800 }} />
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  // Render "+" button when the product is not in the cart
                  <div
                    style={{ ...styles.addToBag.button }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(product.seller_product_id);
                    }}
                  >
                    <AddIcon style={{ fontSize: 16, fontWeight: 800 }} />
                  </div>
                )}
              </div>

              {/* END Add to bag section */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: displayType === "vertical" ? 1 : 1.1,
              width: "100%",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "flex-start",
              height: "100%",
              padding: displayType === "vertical" ? "0px" : "10px 0px 10px 5px",
            }}
          >
            <div style={{ ...styles.listItemContent }}>
              {displayType === "vertical" ? (
                <>
                  {renderPrice}
                  {renderBrand}
                  {renderName}
                </>
              ) : (
                <>
                  {renderBrand}
                  {renderName}
                  {renderRating}
                  {renderPrice}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Styles object for ListItem component
const styles = {
  listItemContainer: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    margin: 0,
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    height: "100%", // Ensures the container uses full height
    justifyContent: "space-between", // Distributes items with space between
  },
  listItemImageContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    backgroundColor: "#fdfbfe",
    padding: "8px",
    borderRadius: "12px",
  },
  listItemImage: {
    width: "100%",
    objectFit: "cover",
    borderRadius: "6px",
  },
  listItemContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  productName: {
    container: {
      flex: 1,
      paddingBottom: "10px",
    },
    name: {
      fontSize: "16px",
      fontWeight: 400,
      color: "black",
      margin: 0,
      padding: "0 10px",
      fontSize: "14px",
    },
    rating: {
      display: "flex",
      alignItems: "center",
      margin: "5px 0",
    },
    iconStar: {
      color: "#f6b26b",
      fontSize: "10px",
      marginTop: "-2px",
    },
    ratingText: {
      fontWeight: 400,
      fontSize: "14px",
      color: "black",
      marginRight: "5px",
    },
  },
  /* Add to bag section */
  addToBag: {
    container: {
      position: "absolute",
      right: 0,
      marginBottom: "5px",
      display: "flex",
      alignSelf: "flex-end",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      textAlign: "center",
      fontSize: "15px",
      fontWeight: 800,
      backgroundColor: "white",
      borderRadius: "40px",
      border: "1px solid silver",
      height: "40px",
      // shadow
      boxShadow: "0 5px 5px rgba(0,0,0,.1)",
      // changing with size transition
      transition: "width 0.3s ease",
    },
    priceSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: "10px",
      height: "100%",
      flex: 1,
    },
    priceOffer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      fontWeight: 700,
    },
    originalPrice: {
      color: "gray",
      fontSize: "12px",
      textDecoration: "line-through",
      marginRight: "5px",
    },
    salePrice: {
      color: "#1fd400",
      fontWeight: 700,
    },
    price: {
      color: "#06ca49",
      fontWeight: 700,
    },
    button: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: "0px 5px",
      transition: "background-color 0.3s ease",
    },
    quantity: {
      flex: 1,
      padding: "3px 10px",
      transition: "background-color 0.3s ease",
    },
    addedToCart: {
      backgroundColor: "#000",
      color: "white",
    },
  },
  offerRibon: {
    position: "absolute",
    top: 15,
    left: 15,
    padding: "4px 5px",
    backgroundColor: "#ff0000",
    color: "white",
    fontWeight: 600,
    fontSize: "12px",
    zIndex: 1,
    borderRadius: 6,
    // shadow
    boxShadow: "1px 1px 4px rgba(0,0,0,.3)",
  },
};

export default ListItem;
