import React, { useState, useContext } from "react";
import Header from "../../elements/Header";
import { db } from "../../firebase/firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { UserContext } from "../../context/UserContext";
import { EnvContext } from "../../context/EnvContext";

const ProductRequest = () => {
  const { user } = useContext(UserContext);

  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentBrand, setCurrentBrand] = useState("");
  const [currentProduct, setCurrentProduct] = useState("");
  const [isAddingBrand, setIsAddingBrand] = useState(false);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [email, setEmail] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [feedback, setFeedback] = useState("");

  const environment = useContext(EnvContext);

  const collectionPath = environment === "staging" ? "_DEVELOPMENT/stageapi/ProductRequest/ProductRequest/DataBase" : "ProductRequest/ProductRequest/DataBase";

  const handleAddBrand = () => {
    if (currentBrand.trim()) {
      setBrands([...brands, currentBrand.trim()]);
      setCurrentBrand("");
      setIsAddingBrand(false);
    }
  };

  const handleAddProduct = () => {
    if (currentProduct.trim()) {
      setProducts([...products, currentProduct.trim()]);
      setCurrentProduct("");
      setIsAddingProduct(false);
    }
  };

  const handleSubmit = async () => {
    const requestData = {
      userId: user ? user.uid : null,
      email: email.trim() || null,
      brands: [...brands],
      products: [...products],
      additionalInfo: additionalInfo.trim() || null,
      createdAt: new Date().toISOString(),
    };

    try {
      await addDoc(collection(db, collectionPath), requestData);
      setFeedback(
        `You sent a message: Hi Sooperstock, I love shopping on your site and had a thought! Could you please consider adding the following brands to your store: ${brands.join(
          ", "
        )} and it would be amazing if I could also find products like ${products.join(", ")}.`
      );
      setBrands([]);
      setProducts([]);
      setCurrentBrand("");
      setCurrentProduct("");
      setEmail("");
      setAdditionalInfo("");
    } catch (error) {
      console.error("Error submitting request: ", error);
      setFeedback("There was an error submitting your request. Please try again later.");
    }
  };

  return (
    <div>
      <Header title="Suggest a Product or Brand" />
      <div style={{ padding: "20px", fontSize: "26px", lineHeight: "1.6" }}>
        {feedback ? (
          <p style={{ color: "green" }}>{feedback}</p>
        ) : (
          <>
            <p>
              Hi Sooperstock,
              <br />I love shopping on your site and had a thought! Could you please consider adding the following brands to your store:{" "}
              {brands.map((brand, index) => (
                <span key={index} style={{ display: "inline-flex", alignItems: "center", marginRight: "5px" }}>
                  <strong>{brand}</strong>
                  {index < brands.length - 1 && ", "}
                  <span
                    style={{
                      marginLeft: "5px",
                      marginTop: "3px",
                      color: "red",
                      cursor: "pointer",
                      fontWeight: "100",
                      fontSize: "16px",
                    }}
                    onClick={() => setBrands(brands.filter((_, i) => i !== index))}
                  >
                    ✕
                  </span>
                  ,
                </span>
              ))}{" "}
              {!isAddingBrand && (
                <span style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }} onClick={() => setIsAddingBrand(true)}>
                  add brand
                </span>
              )}
              {isAddingBrand && (
                <div style={{ display: "inline-flex", alignItems: "center", position: "relative", borderBottom: "4px solid black", top: "-2px" }}>
                  <input
                    type="text"
                    value={currentBrand}
                    onChange={(e) => setCurrentBrand(e.target.value)}
                    onBlur={handleAddBrand}
                    placeholder="Type a brand"
                    style={{
                      fontSize: "22px",
                      lineHeight: "1.5",
                      padding: "0px",
                      marginLeft: "5px",
                      verticalAlign: "middle",
                      border: 0,
                    }}
                    autoFocus
                  />
                </div>
              )}
              , and it would be amazing if I could also find products like{" "}
              {products.map((product, index) => (
                <span key={index} style={{ display: "inline-flex", alignItems: "center", marginRight: "5px" }}>
                  <strong>{product}</strong>
                  {index < products.length - 1 && ", "}
                  <span
                    style={{
                      marginLeft: "5px",
                      marginTop: "3px",
                      color: "red",
                      cursor: "pointer",
                      fontWeight: "100",
                      fontSize: "16px",
                    }}
                    onClick={() => setProducts(products.filter((_, i) => i !== index))}
                  >
                    ✕
                  </span>
                  ,
                </span>
              ))}{" "}
              {!isAddingProduct && (
                <span style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }} onClick={() => setIsAddingProduct(true)}>
                  add product
                </span>
              )}
              {isAddingProduct && (
                <div style={{ display: "inline-flex", alignItems: "center", position: "relative", borderBottom: "4px solid black", top: "-2px" }}>
                  <input
                    type="text"
                    value={currentProduct}
                    onChange={(e) => setCurrentProduct(e.target.value)}
                    onBlur={handleAddProduct}
                    placeholder="Type a product"
                    style={{
                      fontSize: "22px",
                      lineHeight: "1.5",
                      padding: "0px",
                      marginLeft: "5px",
                      verticalAlign: "middle",
                      border: 0,
                    }}
                    autoFocus
                  />
                </div>
              )}
              .
            </p>

            <p style={{ marginTop: 20, color: "gray" }}>If you want to tell more or share a link, do it here:</p>
            <textarea
              placeholder="Additional information..."
              style={{ width: "100%", minHeight: "100px", fontSize: "18px", padding: 8, borderRadius: 6, borderColor: "silver" }}
            ></textarea>

            <p style={{ marginTop: 20, color: "gray" }}>
              I<label>Email (optional):</label>
              <input
                type="email"
                placeholder="Your email address"
                style={{ width: "100%", minHeight: "100px", fontSize: "18px", padding: 8, borderRadius: 6, borderColor: "silver" }}
              />
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
              }}
            >
              <div
                onClick={handleSubmit}
                style={{
                  fontSize: "18px",
                  marginTop: "20px",
                  background: "red",
                  color: "white",
                  padding: "10px 20px",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                Send Request
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductRequest;
