// HorizontalListing.js
import React, { Component } from "react";
import ListItem from "./ListItem";
import "../styles/global.css";
import { borderRadius, fontSize } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

class HorizontalListing extends Component {
  render() {
    const { title, products, categoryLink } = this.props;

    return (
      <div className="horizontal-listing">
        <div style={styles.title}>{title}</div>
        <div className="horizontal-listing-container" style={styles.container}>
          {products && products.length > 0 ? (
            <>
              {products.map((product, index) => (
                <div key={product.id || index} style={styles.item}>
                  <ListItem product={product} index={index} dataMultiple={products} />
                </div>
              ))}
              {/* Show more button */}
              {categoryLink && (
                <div
                  style={{
                    ...styles.showMore,
                  }}
                  onClick={() =>
                    // link to categoryLink. Format is like /category/123 or /category/abc
                    (window.location.href = categoryLink)
                  }
                >
                  <div>See All</div>
                  <ArrowForwardIosIcon style={{ fontSize: 12, marginLeft: 5 }} />
                </div>
              )}
            </>
          ) : (
            <p style={styles.empty}>No products found.</p>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    overflowX: "auto",
    padding: "10px 0",
    gap: "10px",
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    margin: "10px 10px",
  },
  item: {
    flex: "0 0 auto",
    width: "150px", // Adjust based on your design
  },
  empty: {
    fontSize: "1rem",
    color: "gray",
    marginLeft: "10px",
  },
  showMore: {
    width: "120px",
    height: "120px",
    display: "flex",
    backgroundColor: "#f5f5f5",
    margin: "15px 0",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
    color: "gray",
    cursor: "pointer",
  },
};

export default HorizontalListing;
