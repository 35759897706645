import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc, deleteField, updateDoc } from "firebase/firestore";
import { logAnalyticsEvent, db } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import "../styles/global.css";
import { UserContext } from "../context/UserContext";
import { auth } from "../firebase/firebaseConfig";
import { EnvContext } from "../context/EnvContext";
import config from "../config";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

export const MenuDrawer = ({ isOpen, toggleMenu }) => {
  const environment = useContext(EnvContext);
  const navigate = useNavigate();
  const drawerRef = useRef(null);
  const [showSignOutConfirmation, setShowSignOutConfirmation] = useState(false);

  const { user } = useContext(UserContext);

  //
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    // Initialize Feather icons
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        toggleMenu(); // Close the drawer if clicked outside
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, toggleMenu]);

  const deleteCookie = async () => {
    try {
      // Reference to the user's document in Firestore
      const docRef = doc(db, "Users/UsersList/DataBase", user.uid);

      // Check if the document exists
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Delete the "cookie" field from the document
        await updateDoc(docRef, {
          cookie: deleteField(),
        });

        alert("Cookie settings have been deleted.");
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      alert(JSON.stringify(error));
      console.error("Error deleting cookie:", error);
    }
  };

  return (
    <>
      <Dialog open={showSignOutConfirmation} onClose={() => setShowSignOutConfirmation(false)}>
        <DialogTitle>Are you sure you want to sign out?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowSignOutConfirmation(false)} variant="" sx={{ background: "black", color: "white" }}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                await signOut(auth);
                setShowSignOutConfirmation(false); // Close modal after signing out
                navigate("/");
              } catch (error) {
                console.error("Error signing out:", error);
              }
            }}
            color=""
            variant="outlined"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {isOpen && (
        <div
          style={styles.overlay} // Overlay to block clicks on the content below
          onClick={toggleMenu} // Close the drawer when clicking on the overlay
        />
      )}
      <div
        ref={drawerRef}
        style={{
          ...styles.drawer,
          transform: isOpen ? "translateX(0)" : "translateX(-100%)",
        }}
      >
        <div style={styles.header.container}>
          <div style={styles.header.toggleMenu}>
            <button onClick={toggleMenu} style={styles.closeButton}>
              <i data-feather="x" />
            </button>
          </div>
        </div>
        {user && user?.isAnonymous === false && (
          /** REGISTERED USER */
          <nav style={styles.nav}>
            <div style={{}}>
              <div style={styles.header.container}>
                <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "35px" }} />
                <p
                  style={{
                    marginBottom: 20,
                    fontSize: 12,
                    textTransform: "uppercase",
                  }}
                >
                  The specialty goods superstore
                </p>
              </div>
              <ul style={styles.list}>
                <li
                  style={{
                    ...styles.listingItem.container,
                    paddingBottom: 30,
                  }}
                >
                  {environment === "staging" ? (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", border: "1px solid #ddd", padding: 10 }}>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <p style={styles.listingItem.title}>Sooper Cash</p>
                        <p style={{ fontWeight: 600 }}>
                          {user?.staging_wallet_amount !== undefined && (
                            <>
                              {user.staging_wallet_amount === 0 ? (
                                <span style={{ color: "gray" }}>$0</span>
                              ) : user.staging_wallet_amount > 0 ? (
                                <span style={{ color: "#1fd400" }}>${user.staging_wallet_amount.toFixed(2)}</span>
                              ) : (
                                <span style={{ color: "red" }}>-${Math.abs(user.staging_wallet_amount).toFixed(2)}</span>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          fontWeight: 300,
                          opacity: 0.5,
                          fontSize: 12,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 4,
                        }}
                      >
                        <p style={{ ...styles.listingItem.title }}>Pending Sooper Cash</p>
                        <p style={{}}>
                          <span style={{}}>
                            <span style={{}}>$</span>
                            {user.staging_wallet_pending_amount || "0.00"}
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", border: "1px solid #ddd", padding: 10 }}>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <p style={styles.listingItem.title}>Sooper Cash</p>
                        <p style={{ fontWeight: 600 }}>
                          {user?.wallet_amount !== undefined ? (
                            <>
                              {user.wallet_amount === 0 ? (
                                <span style={{ color: "gray" }}>$0</span>
                              ) : user.wallet_amount > 0 ? (
                                <span style={{ color: "#1fd400" }}>${user.wallet_amount.toFixed(2)}</span>
                              ) : (
                                <span style={{ color: "red" }}>-${Math.abs(user.wallet_amount).toFixed(2)}</span>
                              )}
                            </>
                          ) : (
                            <span style={{ color: "gray" }}>$0</span>
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          fontWeight: 300,
                          opacity: 0.5,
                          fontSize: 12,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 4,
                        }}
                      >
                        <p style={{ ...styles.listingItem.title }}>Pending Sooper Cash</p>
                        <p style={{}}>
                          <span style={{}}>
                            <span style={{}}>$</span>
                            {user.wallet_pending_amount || "0.00"}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Shop",
                      environment: environment,
                    });
                    toggleMenu();
                    navigate("/");
                  }}
                >
                  <p style={styles.listingItem.title}>Shop</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Account",
                      environment: environment,
                    });
                    toggleMenu();
                    navigate("/useraccount");
                  }}
                >
                  <p style={styles.listingItem.title}>Account</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Order History",
                      environment: environment,
                    });
                    toggleMenu();
                    navigate("/orderhistory");
                  }}
                >
                  <p style={styles.listingItem.title}>Order history</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Saved Addresses",
                      environment: environment,
                    });
                    toggleMenu();
                    navigate("/useraddresses");
                  }}
                >
                  <p style={styles.listingItem.title}>Saved addresses</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "FAQ",
                      environment: environment,
                    });
                    toggleMenu();
                    navigate("/faq");
                  }}
                >
                  <p style={styles.listingItem.title}>FAQ</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Help",
                      environment: environment,
                    });
                    toggleMenu();
                    navigate("/help");
                  }}
                >
                  <p style={styles.listingItem.title}>Help</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Product Request",
                      environment: environment,
                    });
                    toggleMenu();
                    navigate("/product-request");
                  }}
                >
                  <p style={styles.listingItem.title}>Product Request</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={{ ...styles.listingItem.container }}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Earn Rewards",
                      environment: environment,
                    });
                    toggleMenu();
                    navigate("/share");
                  }}
                >
                  <p style={styles.listingItem.title}>Earn rewards!</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Legal",
                      environment: environment,
                    });
                    toggleMenu();
                    navigate("/legal");
                  }}
                >
                  <p style={styles.listingItem.title}>Legal</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                {user?.cookie && (
                  <li style={{ ...styles.listingItem.container, display: "none" }} onClick={() => deleteCookie()}>
                    <p style={styles.listingItem.title}>Delete Cookies</p>
                  </li>
                )}

                {user ? (
                  <li
                    style={styles.listingItem.container}
                    onClick={() => {
                      // analytics
                      logAnalyticsEvent("menu_click", {
                        item: "Sign out",
                        environment: environment,
                      });
                      // toggle menu off
                      toggleMenu();
                      setShowSignOutConfirmation(true);
                    }}
                  >
                    <p style={styles.listingItem.title}>Sign out</p>
                    <p>
                      <i data-feather="chevron-right" style={styles.listingItem.icon} />
                    </p>
                  </li>
                ) : (
                  <li
                    style={styles.listingItem.container}
                    onClick={() => {
                      // analytics
                      logAnalyticsEvent("menu_click", {
                        item: "Sign in",
                        environment: environment,
                      });
                      navigate(`/signin`);
                    }}
                  >
                    <p style={styles.listingItem.title}>Sign in</p>
                    <p>
                      <i data-feather="chevron-right" style={styles.listingItem.icon} />
                    </p>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        )}
        {(!user || user?.isAnonymous == true) && (
          /** GUEST USER / UNREGISTERED */
          <nav style={styles.nav}>
            <div style={{}}>
              <div style={styles.header.container}>
                <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "35px" }} />
                <p
                  style={{
                    marginBottom: 20,
                    fontSize: 11,
                    textTransform: "uppercase",
                  }}
                >
                  The specialty goods superstore
                </p>
              </div>
              <ul style={{ ...styles.list, marginTop: 50 }}>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Shop",
                      environment: environment,
                    });
                    navigate("/");
                  }}
                >
                  <p style={styles.listingItem.title}>Shop</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "FAQ",
                      environment: environment,
                    });
                    navigate("/faq");
                  }}
                >
                  <p style={styles.listingItem.title}>FAQ</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Help",
                      environment: environment,
                    });
                    navigate("/help");
                  }}
                >
                  <p style={styles.listingItem.title}>Help</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Sign Up",
                      environment: environment,
                    });
                    navigate(`/signUp`);
                  }}
                >
                  <p style={styles.listingItem.title}>Sign Up</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
                <li
                  style={styles.listingItem.container}
                  onClick={() => {
                    // analytics
                    logAnalyticsEvent("menu_click", {
                      item: "Sign In",
                      environment: environment,
                    });
                    navigate(`/signin`);
                  }}
                >
                  <p style={styles.listingItem.title}>Log In</p>
                  <p>
                    <i data-feather="chevron-right" style={styles.listingItem.icon} />
                  </p>
                </li>
              </ul>
            </div>
          </nav>
        )}
        <div style={{}}>
          <p style={{ fontSize: "8px", color: "gray", padding: "20px" }}>{user?.uid || ""}</p>
        </div>
        <p style={{ fontSize: "8px", color: "gray", padding: "20px" }}>v1.6.4</p>
      </div>
    </>
  );
};

const styles = {
  drawer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "300px",
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease",
    zIndex: 10000,
    overflowY: "auto",
    maxHeight: "100vh",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, 0.4)", // Semi-transparent overlay
    zIndex: 9999,
    pointerEvents: "auto",
  },
  header: {
    container: {},
    toggleMenu: {
      textAlign: "right",
      cursor: "pointer",
      padding: "20px 15px",
    },
    userName: {
      fontSize: "24px",
      fontWeight: "600",
      margin: 0,
    },
    memberSince: {
      fontSize: "12px",
      textTransform: "uppercase",
      color: "rgb(150,150,150)",
      margin: 0,
    },
    subscriptionTyle: {
      padding: "3px 0",
      fontSize: "16px",
      margin: 0,
      marginBottom: "30px",
      fontWeight: "200",
      fontSize: "12px",
      textTransform: "uppercase",
      color: "rgb(150,150,150)",
    },
  },
  closeButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  nav: {
    padding: "16px",
  },
  list: {
    listStyle: "none",
    padding: "5px 0 0 0",
  },
  listingItem: {
    container: {
      cursor: "pointer",
      padding: "15px 0",
      display: "flex",
      justifyContent: "space-between",
    },
    icon: {
      color: "gray",
      aspectRatio: "1",
      height: "18px",
    },
  },

  //
};
