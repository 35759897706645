import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GridLoader } from "react-spinners";

const FeaturedCarousel = ({ featuredProducts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideInterval = useRef(null);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const isAutoScrolling = useRef(false); // Flag to distinguish auto-scroll from user interaction

  // Function to start auto-slide
  const startAutoSlide = () => {
    slideInterval.current = setInterval(() => {
      isAutoScrolling.current = true; // Set flag for auto-scrolling
      setCurrentIndex((prevIndex) => (prevIndex + 1) % featuredProducts.length);
    }, 5000); // 5-second interval
  };

  // Clear and restart auto-slide
  const resetAutoSlide = () => {
    if (slideInterval.current) clearInterval(slideInterval.current);
    startAutoSlide();
  };

  // Start auto-slide on mount
  useEffect(() => {
    if (featuredProducts.length > 1) {
      startAutoSlide();
    }
    return () => clearInterval(slideInterval.current); // Clean up
  }, [featuredProducts]);

  // Scroll to current image
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: currentIndex * containerRef.current.offsetWidth * 0.85, // 85% width to show next image
        behavior: "smooth",
      });
    }

    // Reset the auto-scroll flag after scrolling completes
    const timer = setTimeout(() => {
      isAutoScrolling.current = false;
    }, 500); // Allow smooth scroll to complete

    return () => clearTimeout(timer);
  }, [currentIndex]);

  // Update currentIndex on swipe
  useEffect(() => {
    const handleScroll = () => {
      if (isAutoScrolling.current) return; // Ignore scroll event during auto-scroll

      if (containerRef.current) {
        const scrollLeft = containerRef.current.scrollLeft;
        const containerWidth = containerRef.current.offsetWidth * 0.85;
        const newIndex = Math.round(scrollLeft / containerWidth);
        if (newIndex !== currentIndex) {
          setCurrentIndex(newIndex);
          resetAutoSlide(); // Reset auto-slide on user swipe
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentIndex]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    resetAutoSlide(); // Reset auto-slide on dot click
  };

  if (featuredProducts.length === 0) return null;

  return (
    <>
      <div style={styles.title}></div>
      <div ref={containerRef} style={styles.carouselContainer}>
        {featuredProducts.map((product, index) => (
          <div
            onClick={() => {
              if (product.featured_url.startsWith("http://") || product.featured_url.startsWith("https://")) {
                window.location.href = product.featured_url;
              } else {
                navigate(product.featured_url);
              }
            }}
            key={product.id}
            style={{
              ...styles.card,
              flex: featuredProducts.length === 1 ? "0 0 100%" : "0 0 90%",
            }}
          >
            <img src={product.image_url || "https://placehold.co/800x450"} alt={product.title} style={styles.image} />
            <div style={styles.loaderOverlay}>
              <GridLoader />
            </div>
          </div>
        ))}
      </div>

      {featuredProducts.length > 1 && (
        <div style={styles.dotsContainer}>
          {featuredProducts.map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              style={{
                ...styles.dot,
                backgroundColor: currentIndex === index ? "red" : "#ccc",
                opacity: currentIndex === index ? 1 : 0.5,
              }}
            ></div>
          ))}
        </div>
      )}
    </>
  );
};

export default FeaturedCarousel;

const styles = {
  title: {
    padding: "15px 0px 0px 10px",
    fontSize: 12,
    fontWeight: 600,
  },
  carouselContainer: {
    display: "flex",
    overflowX: "auto",
    scrollBehavior: "smooth",
    scrollSnapType: "x mandatory",
    padding: "5px 10px 15px 10px",
    gap: "10px",
    maxWidth: "100%",
    scrollbarWidth: "none",
  },
  card: {
    scrollSnapAlign: "center",
    position: "relative",
    overflow: "hidden",
    borderRadius: "12px",
    maxHeight: "190px",
    maxWidth: "340px",
    border: "1px solid #e0e0e0",
    background: "#fafafa",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    aspectRatio: "800/450",
    zIndex: 10,
    position: "relative",
  },
  loaderOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    zIndex: 0,
    opacity: 0.1,
  },
  dotsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    paddingTop: "10px",
  },
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    cursor: "pointer",
  },
};
