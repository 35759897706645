// src/elements/HeaderShop.js

import React, { useEffect, useState, useContext } from "react";
import "../styles/global.css";
import { useNavigate } from "react-router-dom";
import { MenuDrawer } from "../screens/_menuDrawer";
import SearchFilters from "./SearchFilters";
import { useSearch } from "../context/SearchContext"; // Import context
import { getAuth } from "firebase/auth"; // Import Firebase Auth
import countCartItems from "../functions/countCartItems"; // Import countCartItems function
import { useFirestore } from "../firebase/FirestoreContext"; // Import Firestore context
import { EnvContext } from "../context/EnvContext";
import config from "../config";
import Tooltip from "@mui/material/Tooltip";
import { logAnalyticsEvent } from "../firebase/firebaseConfig";
import EnveironmentStatus from "./EnveironmentStatus";
import StateSelector from "./StateSelector";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import Cookies from "js-cookie";

const HeaderShop = ({ stickyHeader, userState, onCountryChange }) => {
  //
  const environment = useContext(EnvContext);
  //

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const [isSearchEnabled, setSearchEnabled] = useState(false);
  const [cartCount, setCartCount] = useState(0); // State for cart item count
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = useFirestore(); // Get Firestore instance
  const [availableStates, setAvailableStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(userState);

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    window.feather.replace();
  }, [isMenuOpen]);

  useEffect(() => {
    setSearchEnabled(localSearchTerm.length > 3);
  }, [localSearchTerm]);

  useEffect(() => {
    let unsubscribe; // Variable to hold the unsubscribe function

    if (user) {
      // Subscribe to cart item count updates if user is logged in
      unsubscribe = countCartItems(firestore, user.uid, setCartCount, collectionPath);
    } else {
      setCartCount(0); // Set count to 0 if no user is logged in
    }

    // Cleanup listener when the component is unmounted
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [firestore, user]);

  const toggleMenu = () => {
    //
    logAnalyticsEvent("select_content", {
      content_type: "menu",
      item_id: "menu",
      environment: environment,
    });
    //
    setMenuOpen((prev) => !prev);
  };

  //
  // STATES

  const fetchStates = async () => {
    try {
      const statesCollectionRef = collection(firestore, `${collectionPath}SooperstockSettings/ShipmentCompliance/DataBase`);
      const snapshot = await getDocs(statesCollectionRef);
      setAvailableStates(snapshot.docs.map((doc) => doc.data()));
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    fetchStates();
  }, [firestore]);

  const handleSave = async (stateShortName) => {
    console.log("Attempting to save state:", stateShortName);

    try {
      if (user?.uid) {
        // If the user is logged in, save to Firestore
        const userDocRef = doc(firestore, `Users/UsersList/DataBase`, user.uid);
        await updateDoc(userDocRef, { selectedState: stateShortName });
        console.log("State successfully saved to Firestore:", stateShortName);
      } else {
        // If the user is not logged in, save to cookies
        Cookies.set("selectedState", stateShortName, { expires: 7 });
        console.log("State successfully saved to Cookies:", stateShortName);
      }

      // Update the UI
      setSelectedCountry(stateShortName);
      console.log("UI state updated:", stateShortName);

      if (onCountryChange) {
        onCountryChange(stateShortName);
      }
    } catch (error) {
      console.error("Error saving selected state:", error);
      alert("Failed to save selected state.");
    }
  };

  return (
    <>
      <EnveironmentStatus />
      <header
        className="header-shop"
        style={{
          ...styles.headerShop,
          backgroundColor: "#f5f2f2",
        }}
      >
        {/** HEADER */}
        <div style={{ ...styles.header.container }}>
          {/** HEADER FIRST BLOCK*/}
          <div style={{ ...styles.menu.container }}>
            <div onClick={toggleMenu} style={{ display: "flex" }}>
              <i data-feather={isMenuOpen ? "x" : "menu"} style={{ width: "16px", height: "16px" }} />{" "}
            </div>
            {/* State Selector */}
            <div style={{ marginLeft: "15px", cursor: "pointer" }}>
              <StateSelector availableStates={availableStates} onSave={handleSave} selectedState={selectedCountry} />
            </div>
          </div>
          {/** HEADER SECOND BLOCK*/}
          <div style={{ ...styles.logo.container }} onClick={() => navigate("/")}>
            <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "auto", maxHeight: 35, width: "100%" }} />
          </div>
          {/** HEADER THIRD BLOCK*/}
          <div style={{ ...styles.cart.container }}>
            {/** CART ICON */}
            <Tooltip
              title={user && user.uid ? "" : "Start shopping or create an account to add items to your cart"}
              placement="left-start"
              disableHoverListener={!!(user && user.uid)}
            >
              <div
                style={{
                  opacity: user && user.uid ? 1 : 0.5,
                  cursor: user && user.uid ? "pointer" : "none",
                }}
                onClick={
                  user && user.uid
                    ? () => {
                        //
                        logAnalyticsEvent("cart_header_clicked", {
                          content_type: "cart",
                          item_id: "cart_page",
                          environment: environment,
                        });

                        // Navigate to the cart page
                        navigate("/cart");
                      }
                    : null
                }
              >
                <div style={{ width: "16px", height: "16px", position: "relative" }}>
                  <i data-feather={"shopping-bag"} style={{ width: "16px", height: "16px" }} />
                  <div style={styles.cartCount}>{user && user.uid ? cartCount : 0}</div> {/* Display current number of items in cart */}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </header>

      <MenuDrawer isOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </>
  );
};

// Styles for HeaderShop component
const styles = {
  headerShop: (stickyHeader) => ({
    position: stickyHeader ? "sticky" : "relative", // Conditional sticky styling
    top: stickyHeader ? 0 : "auto",
    zIndex: 1000,
    backgroundColor: "#fff",
  }),
  header: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 0",
      borderBottom: ".5px solid #a9a9a9",
    },
  },
  menu: {
    container: {
      flex: 1,
      paddingLeft: "15px",
      cursor: "pointer",
      display: "flex",
    },
  },
  cart: {
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      paddingRight: "20px",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
  },
  cartCount: {
    position: "absolute",
    top: "-9px",
    right: "-13px",
    backgroundColor: "black",
    borderRadius: "50%",
    color: "white",
    fontSize: "10px",
    height: "18px",
    width: "18px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: 600,
    // drop shadow
    boxShadow: "0px 3px 5px rgba(0,0,0,.1)",
  },
  filterIcon: {
    container: {
      position: "relative",
      cursor: "pointer",
      display: "flex",
      alignSelf: "flex-end",
      marginRight: 10,
    },
    dot: {
      position: "absolute",
      top: "-5px",
      right: "-5px",
      backgroundColor: "red",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
    },
  },
  logo: {
    container: {
      flex: 2.5,
      cursor: "pointer",
      display: "flex",
    },
    text: {
      fontSize: "18px",
    },
  },
};

export default HeaderShop;
