import React, { useState, useEffect, useContext } from "react";
import { collection, onSnapshot, getDocs, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import HeaderShop from "../../elements/HeaderShop";
import ListItem from "../../elements/ListItem";
import { useFirestore } from "../../firebase/FirestoreContext";
import "../../styles/global.css";
import Cookies from "js-cookie";
import { UserContext } from "../../context/UserContext";
import NewsletterForm from "./NewsletterForm";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";
import { Helmet } from "react-helmet";
import { logAnalyticsEvent } from "../../firebase/firebaseConfig";
import FeaturedCarousel from "../../elements/FeaturedCarousel";
//
import HorizontalListing from "../../elements/HorizontalListing";

const ShopPage = ({}) => {
  //

  const environment = useContext(EnvContext);

  //
  const db = useFirestore();

  const [loading, setLoading] = useState(true);
  const [recentSearches, setRecentSearches] = useState([]);
  const [contentType, setContentType] = useState("products");
  const [searchResults, setSearchResults] = useState([]);
  //
  const [products, setProducts] = useState([]);
  const [fullProducts, setFullProducts] = useState([]); // Full product list from Firestore
  const [searchTerm, setSearchTerm] = useState("");
  const [shopPageScrollPosition, setShopPageScrollPosition] = useState(() => Cookies.get("ShopPageScrollPosition") || 0);
  const [filters, setFilters] = useState({
    categories: [],
    types: [],
    strain_types: [], // Added strain_types filter
    priceOrder: "",
    inStockOnly: false,
  });
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [userState, setUserState] = useState(null);
  const [lastViewedProducts, setLastViewedProducts] = useState([]);

  const { user, loading: userLoading } = useContext(UserContext);
  const userId = user ? user.uid : null;

  //
  useEffect(() => {
    const fetchUserState = async () => {
      if (userId) {
        const userDocRef = doc(db, `Users/UsersList/DataBase`, userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const newState = userDoc.data().selectedState || null;
          setUserState(newState);
          // then reload products
          applyFilters(filters, searchTerm);
        }
      }
    };

    fetchUserState();
  }, [userId, db, userState]);

  //
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    if (!userLoading && shopPageScrollPosition !== 0) {
      setTimeout(() => {
        window.scrollTo(0, parseFloat(shopPageScrollPosition));
        Cookies.remove(`${collectionPath}ShopPageScrollPosition`);
        setShopPageScrollPosition(0); // Reset scroll position state
      }, 300); // A small delay to allow the page to finish rendering
    }
  }, [userLoading, shopPageScrollPosition]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, `${collectionPath}Listings/ListingsList/DataBase`),
      (snapshot) => {
        const listings = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })).filter((listing) => listing.listing_price > 0);

        let filteredListings = listings;

        // Compliance filter
        // Disabled during development
        /*
         filteredListings = listings.filter((product) => {
          return userState ? product.compliance && product.compliance.includes(userState) : true;
        });
        */

        // Sort filtered listings
        filteredListings.sort((a, b) => {
          if (a.listing_stock_amount === 0 && b.listing_stock_amount !== 0) {
            return 1;
          }
          if (a.listing_stock_amount !== 0 && b.listing_stock_amount === 0) {
            return -1;
          }
          return 0;
        });

        // Sort sooperdeals
        filteredListings.sort((a, b) => {
          const isSooperDealA = a.sooperdeal_start_date && a.sooperdeal_price !== undefined;
          const isSooperDealB = b.sooperdeal_start_date && b.sooperdeal_price !== undefined;

          if (isSooperDealA && !isSooperDealB) return -1;
          if (!isSooperDealA && isSooperDealB) return 1;

          if (isSooperDealA && isSooperDealB) {
            return new Date(a.sooperdeal_start_date) - new Date(b.sooperdeal_start_date);
          }

          return 0;
        });

        // Update state with filtered and sorted listings
        setProducts(filteredListings);
        setFullProducts(filteredListings);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [db, environment, userState, collectionPath]); // Include `userState` in the dependency array

  useEffect(() => {
    if (userState) {
      applyFilters(filters, searchTerm);
    }
  }, [userState, filters, searchTerm]);

  const handleCountryChange = (newCountry) => {
    setUserState(newCountry); // Update userState immediately
  };

  // Feature products
  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        // Reference to the FeaturedList/DataBase collection
        const featuredCollectionRef = collection(db, `${collectionPath}Listings/FeaturedList/DataBase`);
        const featuredSnapshot = await getDocs(featuredCollectionRef);
        const featuredDocs = featuredSnapshot.docs;

        // Map over each document in the snapshot to extract necessary data
        const featuredData = featuredDocs.map((doc) => {
          const productData = doc.data();
          return {
            id: productData.seller_product_id || doc.id, // Fallback to doc.id if seller_product_id is missing
            ...productData,
            product_name: productData.product_name || "N/A",
            brand_name: productData.brand_name || "N/A",
            featured_url: productData.banner_target_url || "",
          };
        });
        setFeaturedProducts(featuredData);
      } catch (error) {
        console.error("Error fetching featured products:", error);
      }
    };

    // Invoke the fetch function
    fetchFeaturedProducts();
  }, [db, collectionPath]);

  // Latest Viewed Products
  const fetchLastViewedProducts = async () => {
    if (!userId) return;

    try {
      // Reference to the RecentlyViewedProducts collection for the user
      const recentlyViewedRef = collection(db, `Users/UsersList/DataBase/${userId}/RecentlyViewedProducts`);
      const recentlyViewedSnapshot = await getDocs(recentlyViewedRef);

      // Sort the IDs by date (descending order) and extract the IDs
      const recentlyViewedIDs = recentlyViewedSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => b.date.toMillis() - a.date.toMillis()) // Sort by date
        .map((item) => item.id);

      // Fetch the listing data for the sorted IDs
      await fetchListingsForLastViewed(recentlyViewedIDs);
    } catch (error) {
      console.error("Error fetching last viewed products:", error);
    }
  };

  const fetchListingsForLastViewed = async (listingIDs) => {
    if (!listingIDs || listingIDs.length === 0) return;

    try {
      // Reference to the Listings collection
      const listingsRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);

      // Fetch all listings from Firestore
      const listingsQuery = await getDocs(listingsRef);

      const listings = listingsQuery.docs
        .filter((doc) => listingIDs.includes(doc.id)) // Filter by matching IDs
        .map((doc) => ({ id: doc.id, ...doc.data() }));

      // Order listings based on the order of IDs
      const orderedListings = listingIDs
        .map((id) => {
          const listing = listings.find((listing) => listing.seller_product_id === id);
          if (!listing) {
            console.warn(`No matching listing found for ID: ${id}`);
          }
          return listing;
        })
        .filter(Boolean); // Remove undefined values

      // Update the state for "Last Viewed" products
      setLastViewedProducts(orderedListings);
    } catch (error) {
      console.error("Error fetching listing data for last viewed products:", error);
    }
  };

  useEffect(() => {
    fetchLastViewedProducts();
  }, [userId, db, collectionPath]);

  //

  const applyFilters = (filters = {}, searchTerm = "") => {
    console.log("applyFilters called with:", filters, searchTerm);
    // No filtering logic for now, but you can customize this later if needed.
  };

  const clearFilters = () => {
    // Reset all filters to their default state
    setFilters({
      categories: [],
      types: [],
      strain_types: [], // Added strain_types reset here
      priceOrder: "",
      inStockOnly: false,
    });
    resetProductsToFullList();
  };

  const resetProductsToFullList = async () => {
    setContentType("products");

    setProducts(fullProducts); // Assuming you have a state for products
  };

  const fetchRecentSearches = async () => {
    if (userId) {
      const searchHistoryRef = collection(db, `Users/UsersList/DataBase/${userId}/SearchHistory`);
      const searchHistorySnapshot = await getDocs(searchHistoryRef);
      // list with doc id and data
      const searches = searchHistorySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecentSearches(searches);
    }
  };

  const deleteFromHistory = async (searchID) => {
    if (userId) {
      try {
        // Create a document reference for the specific search entry
        const searchHistoryRef = doc(db, `Users/UsersList/DataBase/${userId}/SearchHistory/${searchID}`);

        // Delete the document from Firestore
        await deleteDoc(searchHistoryRef);
        // fetch new recent searches
        fetchRecentSearches();
      } catch (error) {
        console.error("Error deleting search history item:", error);
      }
    }
  };

  // useEffects and call fetchRecentSearches when contentType is recentSearches
  useEffect(() => {
    if (contentType === "recentSearches" && user?.uid) {
      fetchRecentSearches();
    }
  }, [contentType]);

  // analytics
  useEffect(() => {
    logAnalyticsEvent("shop_view", {
      page_title: environment == "staging" ? "STAGING - Shop Page" : "Shop Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
      environment: environment,
    });
  }, []);

  const toggleContent = (type) => {
    setContentType(type); // This updates the contentType

    if (type === "recentSearches") {
      console.log("Fetching recent searches"); // Add a log here to verify
      fetchRecentSearches(); // Call fetchRecentSearches when contentType is recentSearches
    }
  };

  const handleSearch = async (searchTerm) => {
    setLoading(true);

    const listingsRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);
    const searchSnapshot = await getDocs(listingsRef);

    const searchResults = searchSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })).filter((listing) => listing.listing_stock_amount !== 0);
    // sort by date

    setSearchResults(searchResults);
    setContentType("searchResults");
    setLoading(false);
  };

  // Show loading screen until user and age gate checks are complete
  if (userLoading || loading) {
    return <div className="loading">Loading...</div>;
  }

  //
  const handleSearchTerm = (term) => {
    setSearchTerm(term);

    if (term === "") {
      // If the search term is exactly an empty string, show all products
      console.log("Empty search term, showing all products");
      setProducts(fullProducts); // Show all items when the search term is cleared
    } else {
      // If there's a search term, apply filters based on the search term
      console.log("Applying filters with term:", term);
      applyFilters({}, term);
    }
  };

  const handleSearchTermClick = (term) => {
    setSearchTerm(term); // Set the clicked search term to state
    applyFilters({}, term);
    setContentType("products");
  };

  //

  return (
    <div className="shop-page">
      <div>
        <Helmet>
          <title>{environment == "staging" ? "STAGING - " : ""}SooperStock</title>
          <meta
            name="description"
            content="The specialty goods superstore — incredible quality, curated selection, and fast delivery all at unbeatable prices. Satisfaction guaranteed or your money back."
          />
          <meta property="og:title" content="SooperStock" />
          <meta
            property="og:description"
            content="The specialty goods superstore — incredible quality, curated selection, and fast delivery all at unbeatable prices. Satisfaction guaranteed or your money back."
          />
          <meta property="og:image" content="" />
          <meta property="og:url" content={`https://sooperstock.com/images/sooperstock_logo.svg`} />
          <meta property="og:type" content="product" />
        </Helmet>
      </div>
      {Cookies.get("ageGate") === "true" && (
        <>
          <HeaderShop
            handleSearchTerm={handleSearchTerm}
            handleSearchTermClick={handleSearchTermClick}
            fetchRecentSearches={fetchRecentSearches}
            toggleContent={toggleContent}
            handleSearch={handleSearch}
            applyFilters={applyFilters}
            clearFilters={clearFilters}
            setContentType={setContentType} // Pass setContentType to HeaderShop
            contentType={contentType} // Pass contentType to HeaderShop
            userState={userState}
            onCountryChange={handleCountryChange}
          />
          <NewsletterForm />

          {contentType === "recentSearches" && (
            <div style={styles.recentSearchesContainer}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    justifyContent: "flex-start",
                    alignSelf: "flex-start",
                  }}
                >
                  <p
                    style={{
                      paddingBottom: 20,
                      padding: "10px 10px 10px 0",
                      marginBottom: 20,
                      cursor: "pointer",
                      textDecoration: "none",
                      fontSize: 13,
                      opacity: 0.7,
                      display: "flex",
                    }}
                    // switch to products
                    onClick={() => setContentType("products")}
                  >
                    &#8249; <span style={{ marginTop: 1, marginLeft: 6 }}>Back</span>
                  </p>
                </div>
              </div>
              <h4 style={{ marginBottom: "" }}>Recent Searches</h4>
              <ul>
                {recentSearches.length > 0 ? (
                  recentSearches.map((search, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px 0",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleSearchTermClick(search.searchTerm)}
                      >
                        {" "}
                        {search?.searchTerm}
                      </div>
                      <div onClick={() => deleteFromHistory(search.id)} style={{ fontSize: 20, cursor: "pointer" }}>
                        &#215;
                      </div>
                    </li>
                  ))
                ) : (
                  <p style={{ padding: "20px 0", opacity: 0.5 }}>No recent searches</p>
                )}
              </ul>
            </div>
          )}
          {contentType === "searchResults" && (
            <div style={styles.productGrid}>
              {products.length > 0 ? (
                products.map((product, index) => <ListItem key={index} product={product} index={index} dataMultiple={products} />)
              ) : (
                <div
                  style={{
                    width: "100vw",
                    textAlign: "center",
                    padding: "50px 0",
                  }}
                >
                  No results found
                </div>
              )}
            </div>
          )}
          <FeaturedCarousel featuredProducts={featuredProducts} />
          {searchTerm === "" && featuredProducts.length > 0 && (
            <div style={{ ...styles.featuredGrid, display: "none" }}>
              <h3 style={{ padding: "15px 10px 10px 10px", fontSize: 18, fontWeight: "300" }}>Featured</h3>
              <div style={styles.productGrid}>
                {featuredProducts.map((product, index) => (
                  <ListItem key={product.id} product={product} index={index} dataMultiple={featuredProducts} />
                ))}
              </div>
            </div>
          )}
          {contentType === "products" && (
            <>
              {featuredProducts.length > 0 && searchTerm === "" && (
                <h3 style={{ padding: "15px 10px 10px 10px", fontSize: 18, fontWeight: "300", display: "none" }}>Products</h3>
              )}
              {lastViewedProducts.length > 0 && <HorizontalListing title="Last viewed" products={lastViewedProducts} />}

              {/* Horizontal sections for categories */}
              <HorizontalListing
                title="Beverage"
                categoryLink="category/beverage"
                products={products.filter((product) => product.product_category?.includes("Beverage"))}
              />

              <HorizontalListing
                title="Edibles"
                categoryLink="category/edibles"
                products={products.filter((product) => ["Gummy", "Chocolate", "Edibles"].some((cat) => product.product_category?.includes(cat)))}
              />

              <HorizontalListing
                title="Flower"
                categoryLink="category/flower"
                products={products.filter((product) => product.product_category?.includes("Flower"))}
              />

              <HorizontalListing
                title="Preroll"
                categoryLink="category/preroll"
                products={products.filter((product) => product.product_category?.includes("Preroll"))}
              />

              <HorizontalListing
                title="Vape"
                categoryLink="category/vape"
                products={products.filter((product) => ["Dispo", "Carts", "Pods", "Battery"].some((cat) => product.product_category?.includes(cat)))}
              />

              <HorizontalListing
                title="Accessory"
                categoryLink="category/accessory"
                products={products.filter((product) => product.product_category?.includes("Accessory"))}
              />

              <HorizontalListing title="Deals" categoryLink="category/deals" products={products.filter((product) => product.isOnSale)} />

              <div style={styles.productGrid}>
                {products.length > 0 ? (
                  products.map((product, index) => <ListItem key={index} product={product} index={index} dataMultiple={products} />)
                ) : (
                  <div
                    style={{
                      width: "100vw",
                      textAlign: "center",
                      padding: "50px 0",
                    }}
                  >
                    No results found
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  productGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    justifyContent: "start",
    boxSizing: "border-box",
  },
  recentSearchesContainer: {
    marginBottom: "20px",
    padding: "10px",
    borderRadius: "5px",
  },
};

export default ShopPage;
