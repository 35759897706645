// src/elements/HeaderShop.js

import React, { useEffect, useState, useContext } from "react";
import "../styles/global.css";
import { useNavigate } from "react-router-dom";
import { MenuDrawer } from "../screens/_menuDrawer";
import SearchFilters from "./SearchFilters";
import { useSearch } from "../context/SearchContext";
import { getAuth } from "firebase/auth";
import countCartItems from "../functions/countCartItems";
import { useFirestore } from "../firebase/FirestoreContext";
import { EnvContext } from "../context/EnvContext";
import { useSwipeable } from "react-swipeable";
import Tooltip from "@mui/material/Tooltip";
import config from "../config";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import StateSelector from "./StateSelector";
import Cookies from "js-cookie";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EnveironmentStatus from "./EnveironmentStatus";

import { logAnalyticsEvent } from "../firebase/firebaseConfig";
import { borderRadius, display, fontSize } from "@mui/system";
// mui x font

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const HeaderCategory = ({
  handleSearchTerm,
  applyFilters,
  clearFilters,
  setContentType,
  contentType,
  userState,
  onCountryChange,
  categoryName,
  hideCategories,
}) => {
  // environment
  const environment = useContext(EnvContext);
  //
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const [isSearchEnabled, setSearchEnabled] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [filters, setFilters] = useState({
    categories: [],
    types: [],
    priceOrder: "",
    inStockOnly: false,
  });
  const [recentSearchTerms, setRecentSearchTerms] = useState([]);
  const [openFilter, setOpenFilter] = useState(null);
  const [availableStates, setAvailableStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(userState || "Select");

  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = useFirestore(); // Get Firestore instance

  const searchContext = useSearch(); // Use search context
  const { setSearchTerm, searchProducts, storeSearchTerm, getHistoryTerms, toggleRecentSearches, showRecentSearches } = searchContext || {}; // Destructure with fallback

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    window.feather.replace();
  }, [isMenuOpen]);

  useEffect(() => {
    setSearchEnabled(localSearchTerm.length > 3);
  }, [localSearchTerm]);

  useEffect(() => {
    let unsubscribe; // Variable to hold the unsubscribe function

    if (user?.uid) {
      // Subscribe to cart item count updates if user is logged in
      unsubscribe = countCartItems(firestore, user.uid, setCartCount, collectionPath);
    } else {
      setCartCount(0); // Set count to 0 if no user is logged in
    }

    // Cleanup listener when the component is unmounted
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [firestore, user]);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setLocalSearchTerm(value); // Update input state
    setSearchTerm(value); // Update search term in context
    handleSearchTerm(value); // Pass the search term up

    if (value.length === 0) {
      // Clear the search and reset filters without flickering
      clearFilters(); // Ensure this function resets all filters and shows all products
      setContentType("products"); // Switch back to products content
    } else {
      // Apply filters based on the search term
      setContentType("products");
    }
  };

  const handleSearchClick = async () => {
    if (isSearchEnabled) {
      await storeSearchTerm(localSearchTerm); // Store the search term
      applyFilters(filters, localSearchTerm); // Apply filters with the search term
      setContentType("searchResults"); // Switch to search results content
      searchProducts(localSearchTerm); // Perform search
      toggleRecentSearches(false); // Hide recent searches
    }
  };

  const handleFocus = async () => {
    const terms = await getHistoryTerms(); // Fetch recent searches
    setRecentSearchTerms(terms); // Store them in state
    setContentType("recentSearches"); // Set content type to "recentSearches"
    toggleRecentSearches(true); // Show recent searches
  };

  return (
    <>
      <EnveironmentStatus />
      <header
        className="header-shop"
        style={{
          ...styles.headerShop,
          backgroundColor: "#f5f2f2",
        }}
      >
        {/** HEADER */}
        <div style={{ ...styles.header.container }}>
          {/** HEADER FIRST BLOCK*/}
          <div style={{ ...styles.menu.container }}>
            <div style={styles.backButton} onClick={() => window.history.back()}>
              <ArrowBackIcon style={{ fontSize: 18 }} />
            </div>
          </div>
          {/** HEADER SECOND BLOCK*/}
          <div style={{ ...styles.logo.container }} onClick={() => navigate("/")}>
            <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "auto", maxHeight: 35, width: "100%" }} />
          </div>
          {/** HEADER THIRD BLOCK*/}
          <div style={{ ...styles.cart.container }}>
            {/** FILTER ICON */}
            <div
              style={{ ...styles.filterIcon.container, display: "none" }}
              onClick={() => setOpenFilter(openFilter === "other-filters" ? null : "other-filters")}
            >
              <i data-feather="sliders" style={{ width: "16px", height: "16px", position: "relative" }} />
              {filters.priceOrder ||
              filters.inStockOnly ||
              filters?.categories?.product_category?.length > 0 ||
              filters?.types?.length > 0 ||
              filters.strain_types?.length > 0 ||
              filters.isOnSale ? (
                <div style={styles.filterIcon.dot} />
              ) : null}
            </div>
            {/** CART ICON */}
            <Tooltip
              title={user && user.uid ? "" : "Start shopping or create an account to add items to your cart"}
              placement="left-start"
              disableHoverListener={!!(user && user.uid)}
            >
              <div
                style={{
                  opacity: user && user.uid ? 1 : 0.5,
                  cursor: user && user.uid ? "pointer" : "none",
                }}
                onClick={
                  user && user.uid
                    ? () => {
                        // Track the cart click event
                        logAnalyticsEvent("select_content", {
                          content_type: "cart",
                          item_id: "cart_page",
                          environment: environment,
                        });

                        // Navigate to the cart page
                        navigate("/cart");
                      }
                    : null
                }
              >
                <div style={{ width: "16px", height: "16px", position: "relative" }}>
                  <i data-feather={"shopping-bag"} style={{ width: "16px", height: "16px" }} />
                  <div style={styles.cartCount}>{user && user.uid ? cartCount : 0}</div> {/* Display current number of items in cart */}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
        {/** SEARCH AREA */}
        <div style={{ ...styles.search.container }}>
          <form
            style={{ ...styles.search.inputFieldContainer }}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent full page reload
              handleSearchClick(); // Custom search handler
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 10px 0px 10px",
                position: "relative",
              }}
            >
              {/* Search Icon */}
              <div
                style={{
                  position: "absolute",
                  width: "30px",
                  bottom: "12px",
                  left: "10px",
                  justifyContent: "flex-end",
                  alignContent: "center",
                  textAlign: "right",
                  fontSize: "10px !important",
                  opacity: 0.3,
                }}
              >
                <i data-feather="search" style={{ width: "14px", height: "14px" }} />
              </div>

              {/* Search Input */}
              <input
                type="search"
                placeholder="Search SooperStock"
                style={{ ...styles.search.inputField, paddingLeft: "30px" }}
                value={localSearchTerm}
                onChange={handleSearchInputChange}
                onFocus={user?.uid && handleFocus}
                onBlur={() => toggleRecentSearches(false)}
                inputMode="search"
                enterKeyHint="search"
              />

              {/* Clear Search Term */}
              {isSearchEnabled && (
                <div
                  style={{
                    flex: 1,
                    padding: "0px 20px",
                    color: "black",
                    alignItems: "center",
                    display: "flex",
                    zIndex: 1000,
                    fontWeight: "300",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    clearFilters();
                    setLocalSearchTerm(""); // Clear input value
                    setSearchTerm(""); // Reset context search term
                    setContentType("products");
                    handleSearchTerm("");
                  }}
                >
                  &#10005;
                </div>
              )}
            </div>
          </form>
        </div>
        {contentType !== "recentSearches" && (
          <SearchFilters openFilter={openFilter} setOpenFilter={setOpenFilter} categoryName={categoryName} hideCategories={hideCategories} />
        )}
      </header>
    </>
  );
};

// Styles for HeaderShop component (unchanged)
const styles = {
  headerShop: {},
  header: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 0",
      borderBottom: ".5px solid #a9a9a9",
    },
  },
  menu: {
    container: {
      flex: 1,
      paddingLeft: "15px",
      cursor: "pointer",
      display: "flex",
    },
  },
  cart: {
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      paddingRight: "20px",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
  },
  cartCount: {
    position: "absolute",
    top: "-9px",
    right: "-13px",
    backgroundColor: "black",
    borderRadius: "50%",
    color: "white",
    fontSize: "10px",
    height: "18px",
    width: "18px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: 600,
    // drop shadow
    boxShadow: "0px 3px 5px rgba(0,0,0,.1)",
  },
  filterIcon: {
    container: {
      position: "relative",
      cursor: "pointer",
      display: "flex",
      alignSelf: "flex-end",
      marginRight: 10,
    },
    dot: {
      position: "absolute",
      top: "-5px",
      right: "-5px",
      backgroundColor: "red",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
    },
  },
  logo: {
    container: {
      flex: 2.5,
      cursor: "pointer",
      display: "flex",
    },
    text: {
      fontSize: "18px",
    },
  },
  search: {
    container: {
      flexDirection: "row",
      display: "flex",
      borderBottom: ".5px solid #dadada",
    },
    inputFieldContainer: {
      display: "flex",
      width: "100%",
      height: "65px",
      borderRadius: "0px",
      backgroundColor: "white",
      padding: "10px 0px",
    },
    inputField: {
      width: "100%",
      border: "none",
      height: "100%",
      outline: "none",
      fontSize: "14px",
      borderRadius: "50px",
      backgroundColor: "#f3f3f3",
      paddingLeft: "35px",
    },
  },
  searchButtonContainer: {},
  searchButton: {
    button: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    enabled: {
      backgroundColor: "black",
      color: "white",
      border: "none",
      height: "100%",
      cursor: "pointer",
      width: "100%",
      padding: "0 10px 0 15px",
    },
    disabled: {
      backgroundColor: "gray",
      color: "white",
      border: "none",
      height: "100%",
      cursor: "not-allowed",
      width: "100%",
      padding: "0 10px 0 15px",
    },
  },
  //
  popup: {
    container: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.40)",
      zIndex: 10000,
      overflowY: "auto",
      padding: "20px",
    },
    content: {
      backgroundColor: "white",
      padding: "20px",
    },
    closeButton: {
      position: "absolute",
      top: "40px",
      right: "40px",
      background: "none",
      border: "none",
      fontSize: "20px",
      cursor: "pointer",
      color: "#333",
    },
    countryList: {
      display: "flex",
      flexDirection: "column",
      marginTop: "50px",
    },
    countryItem: {
      padding: "10px 0",
      borderBottom: "1px solid #ddd",
      cursor: "pointer",
      fontSize: "16px",
    },
  },
  //
  backButton: {
    display: "flex",
    backgroundColor: "rgba(100,100,100,.07)",
    width: "40px",
    height: "40px",
    borderRadius: "40px",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default HeaderCategory;
