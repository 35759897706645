import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";
import { useFirestore } from "../../firebase/FirestoreContext";
import "../../styles/global.css";
import HeaderCategory from "../../elements/HeaderCategory";
import ListItem from "../../elements/ListItem";
import { borderRadius, display, height, textAlign, width } from "@mui/system";
import { text } from "@fortawesome/fontawesome-svg-core";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const CategoryPage = () => {
  const { catName } = useParams(); // Get category name from the URL
  const db = useFirestore(); // Firestore instance
  // environment
  const environment = useContext(EnvContext);
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const applyFilters = (filters = {}, searchTerm = "") => {
    console.log("applyFilters called with:", filters, searchTerm);
    // No filtering logic for now, but you can customize this later if needed.
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, `${collectionPath}Listings/ListingsList/DataBase`),
      (snapshot) => {
        const normalizedCategory = catName.trim().toLowerCase(); // Normalize the category from URL
        const categoryProducts = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(
            (product) =>
              product.product_category && // Ensure the field exists
              product.product_category.trim().toLowerCase() === normalizedCategory // Normalize and compare
          );

        // Sort products: in-stock first, out-of-stock last
        categoryProducts.sort((a, b) => {
          if (a.listing_stock_amount === 0 && b.listing_stock_amount !== 0) return 1;
          if (a.listing_stock_amount !== 0 && b.listing_stock_amount === 0) return -1;
          return 0;
        });

        setProducts(categoryProducts);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching category products:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Cleanup on component unmount
  }, [db, catName]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="category-page">
      <HeaderCategory categoryName={catName} applyFilters={applyFilters} hideCategories={true} />

      <div style={styles.productGrid}>
        {products.length > 0 ? (
          products.map((product) => <ListItem key={product.id} product={product} displayType={"horisontal"} />)
        ) : (
          <div style={styles.noProducts}>No products found in this category.</div>
        )}
      </div>
    </div>
  );
};

const styles = {
  productGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    padding: "0px 0px",
  },
  noProducts: {
    textAlign: "center",
    padding: "50px 0",
    fontSize: "18px",
    opacity: 0.7,
  },
  backButton: {
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    margin: "5px 0px 0 15px",
    background: "rgba(100,100,100,.07)",
    cursor: "pointer",
    borderRadius: "50%",
    paddingTop: "5px",
  },
};

export default CategoryPage;
